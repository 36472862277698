// header.js

// Get the header element
const header = document.querySelector(".header");

// Add a scroll event listener to the window
window.addEventListener("scroll", function () {
	// Check if the window has scrolled beyond a certain threshold
	if (window.scrollY > 100) {
		// Adjust the threshold value as needed
		// Add a CSS class to change the background color
		header.classList.add("scrolled");
	} else {
		// Remove the CSS class if the scroll position is below the threshold
		header.classList.remove("scrolled");
	}
});
